import React       from 'react';
import PropTypes   from 'prop-types';
import { Helmet }  from 'react-helmet';

import SvrPreviewToolbar from '../components/SvrPreviewToolbar';
import SvrPreview        from '../components/preview/SvrPreview';

const SvrPreviewPage = ({
  onExit,
  title,
  heroImageUrl,
  preSections,
  duringSections,
  postSections,
  isUuid
}) => (
  <div>
    <Helmet>
      <title>Preview SVR</title>
    </Helmet>
    <SvrPreviewToolbar onExit={onExit} />
    <SvrPreview
      preSections={preSections}
      duringSections={duringSections}
      postSections={postSections}
      title={title}
      heroImageUrl={heroImageUrl}
      isUuid={isUuid}
    />
  </div>
);

SvrPreviewPage.propTypes = {
  onExit         : PropTypes.func.isRequired,
  title          : PropTypes.string,
  heroImageUrl   : PropTypes.string,
  preSections    : PropTypes.array,
  duringSections : PropTypes.array,
  postSections   : PropTypes.array,
};

SvrPreviewPage.defaultProps = {
  title          : '',
  preSections    : [],
  duringSections : [],
  postSections   : []
};

export default SvrPreviewPage;
